export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    financialReportSetID: null,
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],

    filters: {
      show: true
    },

    region: {},

    rows: [],

    columns: [
      {
        field: 'reportName',
        label: 'Report Name',
        aria: 'Report Name',
        width: '95%',
        sortable: false,
        searchable: false
      }
    ],

    styles: {
      formRow: {
        padding: '10px',
        whiteSpace: 'nowrap'
      },
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    }
  }
}
