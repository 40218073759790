<template>
  <PageContentLayoutOnly>
    <div>
      <h3 :style="{ display: 'inline-block' }">
        {{ `${$t('reportFinancial.title')}` }}
      </h3>
    </div>

    <ModernTable
      :region="region"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
    />

    <Modal :toggle.sync="toggle">
      <DocumentListModal :financialReportSet="selectedReportSet"> </DocumentListModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import ModernTable from '@/components/tables/Modern/Table'
import DocumentListModal from './components/DocumentListModal/List'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'Reports',
  components: {
    PageContentLayoutOnly,
    Modal,
    ModernTable,
    DocumentListModal
  },

  data,

  computed: {
    ...mapGetters('user', ['authUser']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { reportFinancial: { title: 'Monthly Financial Reports' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
