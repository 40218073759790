/*
  import { financialReportSetStore } from '@/services/FinancialReportSets/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await financialReportSetStore.dispatch ('getFinancialReportSetList', {
      hoaId
    });
  */

  async getFinancialReportSetList({}, { hoaID }) {
    console.log('in getFinancialReportSetList...' + hoaID)
    try {
      const results = await kms.get(`/FinancialReports/FinancialReportSet/List?hoaID=${hoaID}`)

      if (isDebug == true) console.debug('getFinancialReportSetList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The financial report set list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getMergedDocumentsFile({ dispatch }, { financialReportSetID, mergeFileName, done }) {
    const path = `/FinancialReports/FinancialReportSet/RenderMergedFinancialDocument?financialReportSetID=${financialReportSetID}&mergeFileName=${mergeFileName}&asBase64=true&asBase64Raw=true`
    const result = await kms.post(path, {
      financialReportSetID,
      mergeFileName,
      asBase64: true,
      asBase64Raw: true
    })

    if (this.isDebug == true) console.debug('getMergedDocumentsFile=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`The monthly financial reports merged document has been opened in a new tab.`)
      done({ details: result })
    } else {
      console.error(
        'There was a problem retrieving the combined monthly financial reports document.'
      )
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const financialReportSetStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
