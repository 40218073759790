<template>
  <PageContentLayoutOnly>
    <div document-list-modal>
      <h3 :style="{ display: 'inline-block' }">
        {{ `${months[financialReportSet.month - 1]} ${financialReportSet.year}` }}
      </h3>
    </div>

    <div class="pt-3 pb-5">
      <b-button type="is-primary" @click.prevent="viewCombinedFinancialReports"
        >View Combined Financial Reports</b-button
      >
    </div>

    <ModernTable :region="region" :rows="rows" :columns="columns" :loading="loading" />

    <div><b-button type="is-primary" @click.prevent="closeModal">Close</b-button></div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ModernTable from '@/components/tables/Modern/Table'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'FinancialReportDocuments',
  props: {
    financialReportSet: Object
  },

  components: {
    PageContentLayoutOnly,
    ModernTable
  },

  data,

  computed: {
    ...mapGetters('user', ['authUser']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { reportFinancialDocuments: { title: 'Monthly Financial Documents' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
