var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  financialReportSetList: [],
  selectedReportSet: null,
  loading: false,
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],

  filters: {
    show: true
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'monthYear',
      label: 'Month-Year',
      aria: 'Month-Year',
      width: '95%',
      sortable: false,
      searchable: false
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
