<template>
  <b-button
    v-if="(visible && visible == true) || visible == undefined"
    @click.prevent="onClick"
    class="button is-info is-light is-small is-outline is-rounded"
    :disabled="disabled && disabled != undefined && disabled == true"
    >{{ text }}</b-button
  >
</template>

<script>
export default {
  props: ['onClick', 'text', 'visible', 'disabled']
}
</script>
