/*
  import { financialReportDocumentStore } from '@/services/FinancialReportDocuments/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await financialReportDocumentStore.dispatch ('getFinancialReportDocumentList', {
      hoaId
    });
  */

  async getFinancialReportDocumentList({}, { financialReportSetID }) {
    console.log('in getFinancialReportDocumentList...' + financialReportSetID)
    try {
      const results = await kms.get(
        `/FinancialReports/FinancialReportDocument/List?financialReportSetID=${financialReportSetID}`
      )

      if (isDebug == true)
        console.debug('getFinancialReportDocumentList log=' + JSON.stringify(results))

      return {
        list: results.results
      }
    } catch (exception) {
      notifyProblem(`The financial report document list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getDocumentFile({ dispatch }, { params, done }) {
    const path = `/FinancialReports/FinancialReportDocument/GetFile`
    const result = await kms.get(path, {
      params
    })

    if (this.isDebug == true) console.debug('getDocumentFile=' + JSON.stringify(result))

    if (dispatch && dispatch != undefined) console.debug(dispatch)

    if (result) {
      console.debug(`The monthly financial report has been opened in a new tab.`)
      done({ details: result })
    } else {
      console.error('There was a problem retrieving the monthly financial report.')
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const financialReportDocumentStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
