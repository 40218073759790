import { financialReportDocumentStore } from '@/services/FinancialReportDocuments/store'
import { financialReportSetStore } from '@/services/FinancialReportSets/store'
import _get from 'lodash/get'
import Button from '@/pages/Management/accounts/reportsFinancial/components/Button'
import { notifyWarning, notifyProblem } from '@/services/notify'
import { base64toBlob, showPdf } from '@/services/blobutility'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    await this.getDocumentList()

    this.loading = false
  },

  closeModal() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  },

  determineReportRows(list) {
    if (this.isDebug == true) console.debug('determineReportRows()...' + JSON.stringify(list))

    if (list && list != undefined) {
      var rows = list.map(entry => {
        const reportName = _get(entry, 'reportName', '')
        const financialReportDocumentID = _get(entry, 'financialReportDocumentID', 0)
        this.financialReportSetID = _get(entry, 'financialReportSetID', 0)

        return {
          reportName,
          view: {
            component: Button,
            props: {
              onClick: () => {
                this.viewReport(financialReportDocumentID)
              },
              text: 'View'
            }
          }
        }
      })

      this.rows = rows
    }
  },

  async getDocumentList() {
    this.loading = true
    try {
      await financialReportDocumentStore
        .dispatch('getFinancialReportDocumentList', {
          financialReportSetID: this.financialReportSet.financialReportSetID
        })
        .then(({ list }) => {
          if (list && list != null) {
            this.financialReportSetList = this.determineReportRows(list)
          }
        })
    } catch (error) {
      console.debug('getDocumentList error=' + error.message)
      this.loading = false
    }

    this.loading = false
  },

  async viewCombinedFinancialReports() {
    console.debug('viewCombinedFinancialReports...' + this.financialReportSetID)

    try {
      await financialReportSetStore.dispatch('getMergedDocumentsFile', {
        financialReportSetID: this.financialReportSetID,
        mergeFileName: 'mergedDocuments',
        done: ({ details }) => {
          if (details) {
            //display merged pdf of combined reports
            const contentType = 'application/pdf'
            const blob = base64toBlob(details, contentType)
            showPdf(blob)
            console.debug(
              `Successfully opened the combined monthly financials report in a new tab.`
            )
            return
          }
        }
      })
    } catch (error) {
      console.debug('getDocumentList error=' + error.message)
      this.loading = false
    }
  },

  async viewReport(financialReportDocumentID) {
    if (this.isDebug == true)
      console.debug('financialReportDocumentID=' + financialReportDocumentID)

    if (financialReportDocumentID == undefined || !financialReportDocumentID) {
      notifyProblem('There is a problem downloading this document.')
    }

    try {
      const params = {
        financialReportDocumentID: financialReportDocumentID,
        asBase64: true,
        asRawBase64: true
      }

      await financialReportDocumentStore.dispatch('getDocumentFile', {
        params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true) console.debug('base64pdf........................' + details)

            const contentType = 'application/pdf'
            const blob = base64toBlob(details, contentType)
            showPdf(blob)
            console.debug(`Successfully opened the monthly financial report in a new tab.`)
            return
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem opening the monthly financial report.')
    }
  },

  open() {
    this.isOpen = true
    this.loading = false
  }
}
