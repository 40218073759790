import { financialReportSetStore } from '@/services/FinancialReportSets/store'
import _get from 'lodash/get'
import Button from '../components/Button'
import { notifyProblem } from '@/services/notify'
import _orderBy from 'lodash/orderBy'

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  determineReportRows(list) {
    if (this.isDebug == true) console.debug('determineReportRows()...' + JSON.stringify(list))

    if (list && list != undefined) {
      //Filter by month descending and only completedTime being populated
      const filteredRows = _orderBy(list, 'month', 'desc').filter(
        f => f.completedTime !== undefined && f.completedTime
      )

      var rows = filteredRows.map(entry => {
        const month = _get(entry, 'month', 0)
        let monthName = ''
        const year = _get(entry, 'year', 0)

        if (month > 0) {
          monthName = this.months[month - 1]
        }

        return {
          monthYear: `${monthName} ${year}`,
          view: {
            component: Button,
            props: {
              onClick: () => {
                this.viewReport(entry)
              },
              text: 'View'
            }
          }
        }
      })

      this.rows = rows
    }
  },

  async reload() {
    this.loading = true

    await this.loadFinancialReportSetList()

    this.loading = false
  },

  async loadFinancialReportSetList() {
    this.toggle = false
    await financialReportSetStore
      .dispatch('getFinancialReportSetList', {
        hoaID: this.hoaId
      })
      .then(({ list }) => {
        if (list && list != null) {
          this.financialReportSetList = this.determineReportRows(list)
        }
      })
  },

  async viewReport(report) {
    if (this.isDebug == true) console.debug('viewReport=' + JSON.stringify(report))

    if (report == undefined || !report) {
      notifyProblem('There is a problem loading report documents.')
      return
    }

    this.selectedReportSet = report
    this.toggle = true
  }
}
